

@media only screen and (min-width: 768px) {
  .bg-img{
    background-image: url('../../images/login-left-new.svg');
    background-size: 90%;
  }
}


/*
.login-main-page {
  background: #fff !important;
  position:unset !important;
}
.login-main-page .login-wrapper {
  background: #fff !important;
  max-width: 'auto' !important;
  height: '100%' ;
  // max-width:auto !important;
}
.sidenav {
  width: 100%;
  top: 0;
  left: 0;
  overflow-x: hidden;
  position: relative;
  vertical-align: middle;
  display: inline-block;
  // background-image: url(../../images/login-background.jpeg) no-repeat scroll 0 0 transparent;
  // background-size: cover;
  background: #001c35 !important;
}

*/